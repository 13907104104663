import NotFound from 'components/NotFound';
import { getEnvVar } from 'config';
import {
  getCurrentUser,
  getCurrentUserEventIds,
  userIsJudge,
  userIsOrganizerNavigatorOrTeamLead,
  userIsOrganizerOrNavigator,
  userIsTeamMember,
} from 'data/reducers';
import { Route, Routes } from 'react-router-dom';
import Account from 'screens/app/Account';
import Dashboard from 'screens/app/Dashboard';
import ShowReadOnlyDimensions from 'screens/app/dimensions/ShowReadOnly';
import ResultHeatmap from 'screens/app/events/components/ResultHeatmap';
import ResultPage from 'screens/app/events/components/ResultPage';
import ResultScoring from 'screens/app/events/components/ResultScoring';
import EventDashboard from 'screens/app/events/Dashboard';
import EventHelp from 'screens/app/events/Help';
import JudgeInvitations from 'screens/app/events/JudgeInvitations';
import JudgeManage from 'screens/app/events/JudgeManage';
import JudgesAssignments from 'screens/app/events/JudgesAssignments';
import OrganizerEditJudge from 'screens/app/events/OrganizerEditJudgeProfile';
import OrganizerEditMembers from 'screens/app/events/OrganizerEditMembers';
import PrintArtifactTeamPdfReport from 'screens/app/events/PrintArtifactTeamPdfReport';
import OrganizerEditTeamProfile from 'screens/app/events/OrganizerEditTeamProfile';
import EventReports from 'screens/app/events/Reports';
import OrganizerRubric from 'screens/app/events/RubricWrapper';
import EventSettings from 'screens/app/events/Settings';
import TeamsAdd from 'screens/app/events/TeamsAdd';
import TeamAssignments from 'screens/app/events/TeamAssignments';
import TeamManage from 'screens/app/events/TeamManage';
import TeamDraft from 'screens/app/events/TeamDraft';
// Organizer screens
import OrganizerWrapper from 'screens/app/events/Wrapper';
import EditJudgeAssignments from 'screens/app/judges/EditAssignments';
import EditJudgeWrapperAsOrganizer from 'screens/app/judges/EditWrapper';
import JudgeEvaluations from 'screens/app/judges/Evaluations';
import JudgeHelp from 'screens/app/judges/Help';
import JudgeProfile from 'screens/app/judges/Profile';
import JudgeScoring from 'screens/app/judges/ScoringWrapper';
//Judge Screens
import JudgeWrapper from 'screens/app/judges/Wrapper';
import OrganizerResultDetails from 'screens/app/results/OrganizerResultDetails';
import EventResults from 'screens/app/results/Results';
import ResultsCompare from 'screens/app/results/ResultsCompare';
//Scoring screens (Judges & Teams)
import ShowScore from 'screens/app/scores/Show';
import Survey from 'screens/app/surveys/Survey';
import EditTeamAssignments from 'screens/app/teams/EditAssignments';
import EditTeamWrapperAsOrganizer from 'screens/app/teams/EditWrapper';
import TeamResultHeatmap from 'screens/app/teams/Heatmap';
import TeamHelp from 'screens/app/teams/Help';
import TeamMembers from 'screens/app/teams/Members';
import TeamProfile from 'screens/app/teams/Profile';
import TeamResultDetails from 'screens/app/teams/ResultDetails';
import TeamResultScores from 'screens/app/teams/Scores';
import TeamScoring from 'screens/app/teams/ScoringWrapper';
// Team screens
import TeamsWrapper from 'screens/app/teams/Wrapper';
import VerifyEmail from 'screens/app/VerifyEmail';
import Wrapper from 'screens/app/Wrapper';
import DeclineInvitationSuccess from 'screens/DeclineInvitationSuccess';
import ForgotPassword from 'screens/ForgotPassword';
import SignUpJudge from 'screens/judges/SignUp';
import SignUpJudgeConfirmation from 'screens/judges/SignUpConfirmation';
import JudgesDeclineInvitation from 'screens/JudgesDeclineInvitation';
import LinkedIn from 'screens/LinkedIn';
import LockoutEmailRequest from 'screens/LockoutEmailRequest';
import Login from 'screens/Login';
import MaintenanceMode from 'screens/MaintenanceMode';
import OauthComplete from 'screens/OauthComplete';
import OauthConfirmCreateUser from 'screens/OauthConfirmCreateUser';
import OauthNeedLogin from 'screens/OauthNeedLogin';
import ResetPassword from 'screens/ResetPassword';
import TeamMembersAcceptInvitation from 'screens/TeamMembersAcceptInvitation';
import TeamMembersDeclineInvitation from 'screens/TeamMembersDeclineInvitation';
import SignUpTeam from 'screens/teams/SignUp';
import SignUpTeamConfirmation from 'screens/teams/SignUpConfirmation';
import UnlockAccount from 'screens/UnlockAccount';
import AppWrapper from 'screens/Wrapper';
import ConditionalRedirect from './ConditionalRedirect';
import withWrapper from './withWrapper';

// Conditional redirects
const UserIsNotAuthenticated = ConditionalRedirect({
  redirectPath: '/app/dashboard',
  selector: (state) => getCurrentUser(state) == null,
});

const UserIsAuthenticated = ConditionalRedirect({
  redirectPath: '/',
  selector: (state) => getCurrentUser(state) != null,
  allowRedirectBack: true,
});

const UserIsFullSignedUpAuthenticated = ConditionalRedirect({
  redirectPath: (state) => getCurrentUser(state)?.get?.('signUpUrl') || '/',
  selector: (state) => {
    if (!getCurrentUser(state)?.get?.('signUpUrl')) return true;
    return !!(getCurrentUserEventIds(state).size || false);
  },
});

const UserIsTeamMember = ConditionalRedirect({
  redirectPath: '/app/dashboard',
  selector: (state, ownProps) => !!userIsTeamMember(state, ownProps),
});

const UserIsJudge = ConditionalRedirect({
  redirectPath: '/app/dashboard',
  selector: userIsJudge,
});

const UserIsOrganizerOrNavigator = ConditionalRedirect({
  redirectPath: '/app/dashboard',
  selector: userIsOrganizerOrNavigator,
});

const UserIsOrganizerNavigatorOrTeamLead = ConditionalRedirect({
  redirectPath: '/app/dashboard',
  selector: userIsOrganizerNavigatorOrTeamLead,
});

// Conditional redirect wrappers
const UserIsAuthenticatedComponent = UserIsAuthenticated(Wrapper);
const UserIsAuthenticatedAndFullSignedUpComponent = UserIsAuthenticated(
  UserIsFullSignedUpAuthenticated(Wrapper),
);
const UserIsOrganizerOrNavigatorComponent = UserIsOrganizerOrNavigator(OrganizerWrapper);
const UserIsOrganizerNavigatorOrTeamLeadComponent =
  UserIsOrganizerNavigatorOrTeamLead(OrganizerWrapper);
const UserIsJudgeComponent = UserIsJudge(JudgeWrapper);
const UserIsTeamMemberComponent = UserIsTeamMember(TeamsWrapper);

// Route wrappers
const UserIsAuthenticatedWrapper = withWrapper(UserIsAuthenticatedComponent);
const UserIsAuthenticatedAndFullSignedUpWrapper = withWrapper(
  UserIsAuthenticatedAndFullSignedUpComponent,
);
const UserIsOrganizerOrNavigatorWrapper = withWrapper(UserIsOrganizerOrNavigatorComponent);
const UserIsOrganizerNavigatorOrTeamLeadWrappper = withWrapper(
  UserIsOrganizerNavigatorOrTeamLeadComponent,
);
const UserIsJudgeComponentWrapper = withWrapper(UserIsJudgeComponent);
const UserIsTeamMemberWrapper = withWrapper(UserIsTeamMemberComponent);
const EditJudgeWrapperAsOrganizerWrapper = withWrapper(EditJudgeWrapperAsOrganizer);
const EditTeamWrapperAsOrganizerWrapper = withWrapper(EditTeamWrapperAsOrganizer);
const OrganizerResultDetailsWrapper = withWrapper(OrganizerResultDetails);
const JudgeScoringWrapper = withWrapper(JudgeScoring);
const TeamResultDetailsWrapper = withWrapper(TeamResultDetails);
const TeamScoringWrapper = withWrapper(TeamScoring);
const OrganizerRubricWrapper = withWrapper(OrganizerRubric);

// Routes
const LoginRoute = UserIsNotAuthenticated(Login);
const DashboardRoute = UserIsAuthenticatedAndFullSignedUpWrapper(Dashboard);
// Organizer Routes
const EventDashboardRoute = UserIsOrganizerNavigatorOrTeamLeadWrappper(EventDashboard);
const EventSettingsRoute = UserIsOrganizerOrNavigatorWrapper(EventSettings);
const EventReportsRoute = UserIsOrganizerNavigatorOrTeamLeadWrappper(EventReports);
const EventHelpRoute = UserIsOrganizerOrNavigatorWrapper(EventHelp);
const EventResultsRoute = UserIsOrganizerNavigatorOrTeamLeadWrappper(EventResults);
const JudgeManageRoute = UserIsOrganizerNavigatorOrTeamLeadWrappper(JudgeManage);
const JudgeInvitationsRoute = UserIsOrganizerOrNavigatorWrapper(JudgeInvitations);
const JudgesAssignmentsRoute = UserIsOrganizerOrNavigatorWrapper(JudgesAssignments);
const SignUpJudgeConfirmationRoute = UserIsOrganizerOrNavigatorWrapper(SignUpJudgeConfirmation);
const EditJudgeAssignmentsRoute = UserIsOrganizerNavigatorOrTeamLeadWrappper(
  EditJudgeWrapperAsOrganizerWrapper(EditJudgeAssignments),
);
const OrganizerEditJudgeRoute = UserIsOrganizerNavigatorOrTeamLeadWrappper(
  EditJudgeWrapperAsOrganizerWrapper(OrganizerEditJudge),
);
const TeamsAddRoute = UserIsOrganizerOrNavigatorWrapper(TeamsAdd);
const TeamAssignmentsRoute = UserIsOrganizerOrNavigatorWrapper(TeamAssignments);
const TeamManageRoute = UserIsOrganizerNavigatorOrTeamLeadWrappper(TeamManage);
const TeamDraftRoute = UserIsOrganizerOrNavigatorWrapper(TeamDraft);
const SignUpTeamConfirmationRoute = UserIsOrganizerOrNavigatorWrapper(SignUpTeamConfirmation);
const EditTeamAssignmentsRoute = UserIsOrganizerNavigatorOrTeamLeadWrappper(
  EditTeamWrapperAsOrganizerWrapper(EditTeamAssignments),
);
const OrganizerEditTeamRoute = UserIsOrganizerNavigatorOrTeamLeadWrappper(
  EditTeamWrapperAsOrganizerWrapper(OrganizerEditTeamProfile),
);
const OrganizerEditMembersRoute = UserIsOrganizerNavigatorOrTeamLeadWrappper(
  EditTeamWrapperAsOrganizerWrapper(OrganizerEditMembers),
);
const PrintArtifactTeamPdfReportRoute = UserIsAuthenticatedWrapper(PrintArtifactTeamPdfReport);
const ResultScoringRoute = UserIsOrganizerNavigatorOrTeamLeadWrappper(
  OrganizerResultDetailsWrapper(ResultScoring),
);
const ResultsCompareRoute = UserIsOrganizerNavigatorOrTeamLeadWrappper(ResultsCompare);
const ResultHeatmapRoute = UserIsOrganizerNavigatorOrTeamLeadWrappper(
  OrganizerResultDetailsWrapper(ResultHeatmap),
);
const ResultPageRoute = UserIsOrganizerNavigatorOrTeamLeadWrappper(
  OrganizerResultDetailsWrapper(ResultPage),
);
const ShowReadOnlyDimensionsRoute = UserIsOrganizerOrNavigatorWrapper(
  OrganizerRubricWrapper(ShowReadOnlyDimensions),
);
const JudgeSignUpJudgeConfirmationRoute = UserIsJudgeComponentWrapper(SignUpJudgeConfirmation);
const JudgeEvaluationsRoute = UserIsJudgeComponentWrapper(JudgeEvaluations);
const JudgeProfileRoute = UserIsJudgeComponentWrapper(JudgeProfile);
const JudgeHelpRoute = UserIsJudgeComponentWrapper(JudgeHelp);
const JudgeShowScoreRoute = UserIsJudgeComponentWrapper(JudgeScoringWrapper(ShowScore));
const TeamProfileRoute = UserIsTeamMemberWrapper(TeamProfile);
const TeamMembersRoute = UserIsTeamMemberWrapper(TeamMembers);
const TeamShowScoreRoute = UserIsTeamMemberWrapper(TeamScoringWrapper(ShowScore));
const TeamSignUpTeamConfirmationRoute = UserIsTeamMemberWrapper(SignUpTeamConfirmation);
const TeamHelpRoute = UserIsTeamMemberWrapper(TeamHelp);
const TeamResultScoresRoute = UserIsTeamMemberWrapper(TeamResultDetailsWrapper(TeamResultScores));
const TeamResultHeatmapRoute = UserIsTeamMemberWrapper(TeamResultDetailsWrapper(TeamResultHeatmap));
const TeamResultPageRoute = UserIsTeamMemberWrapper(TeamResultDetailsWrapper(ResultPage));

const AuthenticatedRoutes = UserIsAuthenticatedWrapper(() => (
  <Routes>
    <Route path="dashboard" element={<DashboardRoute />} />
    <Route path="account" element={<Account />} />
    <Route path="verify-email" element={<VerifyEmail />} />
    {/** ORGANIZER ROUTES */}
    <Route path=":role?/events/:event_id/status" element={<EventDashboardRoute />} />
    <Route path=":role?/events/:event_id/settings" element={<EventSettingsRoute />} />
    <Route path=":role?/events/:event_id/reports" element={<EventReportsRoute />} />
    <Route path=":role?/events/:event_id/help" element={<EventHelpRoute />} />
    <Route path=":role?/events/:event_id/results" element={<EventResultsRoute />} />
    <Route path=":role?/events/:event_id/judges/manage" element={<JudgeManageRoute />} />
    <Route path=":role?/events/:event_id/judges/invite" element={<JudgeInvitationsRoute />} />
    <Route path=":role?/events/:event_id/judges/assign" element={<JudgesAssignmentsRoute />} />
    <Route
      path=":role?/events/:event_id/judges/confirmation"
      element={<SignUpJudgeConfirmationRoute />}
    />
    <Route
      path=":role?/events/:event_id/judges/:judge_profile_id/assignments"
      element={<EditJudgeAssignmentsRoute />}
    />
    <Route
      path=":role?/events/:event_id/judges/:judge_profile_id/profile"
      element={<OrganizerEditJudgeRoute />}
    />
    <Route path=":role?/events/:event_id/teams" element={<TeamAssignmentsRoute />} />
    <Route path=":role?/events/:event_id/teams/add" element={<TeamsAddRoute />} />
    <Route path=":role?/events/:event_id/teams/manage" element={<TeamManageRoute />} />
    <Route path=":role?/events/:event_id/teams/draft" element={<TeamDraftRoute />} />
    <Route
      path=":role?/events/:event_id/teams/:team_id/assignments"
      element={<EditTeamAssignmentsRoute />}
    />
    <Route
      path=":role?/events/:event_id/teams/:team_id/profile"
      element={<OrganizerEditTeamRoute />}
    />
    <Route
      path=":role?/events/:event_id/teams/:team_id/members"
      element={<OrganizerEditMembersRoute />}
    />
    <Route
      path=":role?/events/:event_id/teams/:team_id/print/:artifact_id"
      element={<PrintArtifactTeamPdfReportRoute />}
    />
    <Route
      path=":role?/events/:event_id/teams/confirmation"
      element={<SignUpTeamConfirmationRoute />}
    />
    <Route path=":role?/events/:event_id/results/:evaluation_id" element={<ResultScoringRoute />} />
    <Route
      path=":role?/events/:event_id/results/:evaluation_ids/compare"
      element={<ResultsCompareRoute />}
    />
    <Route
      path=":role?/events/:event_id/results/:evaluation_id/heatmap"
      element={<ResultHeatmapRoute />}
    />
    <Route
      path=":role?/events/:event_id/results/:evaluation_id/score/:score_id"
      element={<ResultScoringRoute />}
    />
    <Route
      path=":role?/events/:event_id/results/:evaluation_id/print"
      element={<ResultPageRoute />}
    />
    <Route
      path="rubrics/events/:event_id/phases/:phase_id"
      element={<ShowReadOnlyDimensionsRoute />}
    />
    {/** JUDGE ROUTES */}
    <Route
      path="events/:event_id/judges/:judge_profile_id/confirmation"
      element={<JudgeSignUpJudgeConfirmationRoute />}
    />
    <Route
      path="events/:event_id/judges/:judge_profile_id/evaluations"
      element={<JudgeEvaluationsRoute />}
    />
    <Route
      path="events/:event_id/judges/:judge_profile_id/my_profile"
      element={<JudgeProfileRoute />}
    />
    <Route path="events/:event_id/judges/:judge_profile_id/help" element={<JudgeHelpRoute />} />
    <Route
      path="events/:event_id/judges/:judge_profile_id/scores/:score_id"
      element={<JudgeShowScoreRoute />}
    />
    {/** TEAM ROUTES */}
    <Route path="events/:event_id/teams/:team_id/my_profile" element={<TeamProfileRoute />} />
    <Route
      path="events/:event_id/teams/:team_id/my_profile/members"
      element={<TeamMembersRoute />}
    />
    {getEnvVar('REACT_APP_GOV_ENV') !== '1' && (
      <Route
        path="events/:event_id/teams/:team_id/self_evaluation"
        element={<TeamShowScoreRoute />}
      />
    )}
    <Route
      path="events/:event_id/teams/:team_id/confirmation"
      element={<TeamSignUpTeamConfirmationRoute />}
    />
    <Route path="events/:event_id/teams/:team_id/help" element={<TeamHelpRoute />} />
    <Route path="events/:event_id/teams/:team_id/results" element={<TeamResultScoresRoute />} />
    <Route
      path="events/:event_id/teams/:team_id/results/heatmap"
      element={<TeamResultHeatmapRoute />}
    />
    <Route
      path="events/:event_id/teams/:team_id/results/score/:score_id"
      element={<TeamResultScoresRoute />}
    />
    <Route
      path="events/:event_id/teams/:team_id/results/:evaluation_id/print"
      element={<TeamResultPageRoute />}
    />
    <Route path="*" element={<NotFound />} />
  </Routes>
));

const AppRoutes = () => (
  <AppWrapper>
    <Routes>
      {/** PUBLIC ROUTES */}
      <Route path="/" element={<LoginRoute />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="reset-password" element={<ResetPassword />} />
      <Route path="unlock_account/:reset_token" element={<UnlockAccount />} />
      <Route path="lockout-email-request/:email" element={<LockoutEmailRequest />} />
      <Route path="maintenance-mode" element={<MaintenanceMode />} />
      <Route path="oauth-complete" element={<OauthComplete />} />
      <Route path="oauth-need-login" element={<OauthNeedLogin />} />
      <Route path="oauth-confirm-create-user" element={<OauthConfirmCreateUser />} />
      <Route path="/linkedIn" element={<LinkedIn />} />
      <Route path="app/events/:event_id/judges/signup" element={<SignUpJudge />} />
      <Route
        path="events/:event_id/judge_invitations/:invitation_id/decline"
        element={<JudgesDeclineInvitation />}
      />
      <Route
        path="events/:event_id/judge_invitations/:invitation_id/decline-success"
        element={<DeclineInvitationSuccess />}
      />
      <Route path="teams/:slug/signup" element={<SignUpTeam key="new-or-latest-team-draft" />} />
      <Route
        path="teams/:slug/signup/drafts/:draftId"
        element={<SignUpTeam key="specific-team-draft" />}
      />
      <Route
        path="teams/:team_id/member_invitations/:invitation_id/decline"
        element={<TeamMembersDeclineInvitation />}
      />
      <Route
        path="teams/:team_id/member_invitations/:invitation_id/decline-success"
        element={<DeclineInvitationSuccess />}
      />
      <Route
        path="teams/:team_id/member_invitations/:invitation_id/accept"
        element={<TeamMembersAcceptInvitation />}
      />
      <Route
        path="survey/events/:event_id/phases/:phase_id/roles/:role_id/type/:type"
        element={<Survey />}
      />
      <Route path="app/*" element={<AuthenticatedRoutes />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </AppWrapper>
);

export default AppRoutes;

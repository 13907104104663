// @ts-expect-error
import TagManager from '@valid-eval/react-gtm-module';
import { Col, Container, Row } from '@valid-eval/shared-react-components';
import { useEffect, useRef } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import withRouter from 'routes/withRouter';

import Loading from 'components/Loading';
import { fetchArtifactItems } from 'data/features/artifactItems';
import { fetchContents } from 'data/features/contents';
import { fetchDocuments } from 'data/features/documents';
import { fromEvents } from 'data/features/events';
import { fetchTeamDraftInEvent, fromTeamDrafts } from 'data/features/teamDrafts';
import { getCurrentUser, getEventCookiesConsent } from 'data/reducers';
import SignUpLogin from 'screens/components/SignUpLogin';

import SignUpWizard from './components/SignUp/SignUpWizard';
import { useEventPolling } from './components/SignUp/useEventPollingHook';
import Title from './components/Title';
import { isRejectedWithValue } from '@reduxjs/toolkit';
import { teamSignUp } from 'utils/urls';

type OwnProps = {
  params: {
    slug: string;
    draftId?: string;
  };
  navigate(path: string): void;
};

const mapStateToProps = (state: any, { params }: OwnProps) => {
  const user = getCurrentUser(state)?.toJS();
  const event = fromEvents.getEventBySlug(state.toJS(), params.slug);
  const loading = fromEvents.getIsLoading(state.toJS());
  const draft = fromTeamDrafts.getTeamDraftInEvent(state.toJS(), {
    id: params.draftId,
    eventId: event?.id || '',
    userId: user?.id || '',
  });
  const cookiesConsent = getEventCookiesConsent(state, event?.id);
  return { user, event, loading, draft, cookiesConsent };
};

const mapDispatchToProps = {
  fetchArtifactItems,
  fetchContents,
  fetchDocuments,
  fetchTeamDraftInEvent,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type SignUpProps = OwnProps & ConnectedProps<typeof connector>;

const SignUp = ({
  draft,
  event,
  fetchArtifactItems,
  fetchContents,
  fetchDocuments,
  fetchTeamDraftInEvent,
  loading,
  params,
  user,
  cookiesConsent,
  navigate,
}: SignUpProps) => {
  const gtmLoaded = useRef(false);

  const fetchDraft = async (eventId: string, eventSlug: string, draftId?: string) => {
    const result = await fetchTeamDraftInEvent({ eventId, draftId });
    if (isRejectedWithValue(result) && draftId) navigate(teamSignUp(eventSlug));
  };

  useEffect(() => {
    const cookiesAccepted =
      cookiesConsent?.saved && cookiesConsent?.tracking && cookiesConsent?.dismissed;
    //Only loads GTM once
    if (event?.gtmId && !gtmLoaded.current && cookiesAccepted) {
      TagManager.initialize({
        gtmId: event.gtmId,
      });
      gtmLoaded.current = true;
    }

    return () => {
      if (event?.gtmId && gtmLoaded.current && cookiesAccepted) {
        window.location.reload();
      }
    };
  }, [event?.gtmId, cookiesConsent]);

  useEventPolling(params.slug);

  // Once event is fetched, fetch the contents
  useEffect(() => {
    if (!event?.id) return;

    fetchContents({ eventId: event.id, pageGroup: 'Team', pageName: 'Signup' });
    fetchDocuments({ eventId: event.id });
  }, [event?.id]);

  // Once user is logged in fetch the team draft in event
  useEffect(() => {
    if (!user?.id || !event?.id || !event?.signup_enabled) return;
    fetchDraft(event.id, event.slug, params.draftId);
  }, [user?.id, event?.id, event?.signup_enabled]);

  useEffect(() => {
    if (!draft?.id) return;

    fetchArtifactItems({ teamId: draft.id });
  }, [draft?.id]);

  return (
    <Container>
      {!event && loading && <Loading />}
      {event && (
        <>
          <Row className="ms-0 me-0">
            <Col md={12} className="mt-5">
              <Title logo={event?.logo_url} title={event?.name} />
            </Col>
          </Row>
          <SignUpWizard />
          <SignUpLogin closable />
        </>
      )}
    </Container>
  );
};

export default withRouter(connector(SignUp));

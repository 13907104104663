import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  isAnyOf,
} from '@reduxjs/toolkit';

import { get, post, put } from 'data/services/config';
import { CreateTeamDraftPayload, TeamDraft, UpdateTeamDraftPayload } from './teamDraftTypes';

// Adapter for operate and normalize the data
export const teamDraftAdapter = createEntityAdapter<TeamDraft>();

const initialState = teamDraftAdapter.getInitialState<{
  saving: boolean;
  loading: boolean;
}>({
  saving: false,
  loading: false,
});

// Actions
export const fetchTeamDraftInEvent = createAsyncThunk(
  'teamDrafts/fetch/draftInEvent',
  async ({ eventId, draftId }: { eventId: string; draftId?: string }, { rejectWithValue }) => {
    try {
      return await get(`/events/${eventId}/team_drafts/${draftId || 'latest'}`);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createTeamDraft = createAsyncThunk(
  'teamDrafts/save/create',
  async ({ eventId, team, isDraft }: CreateTeamDraftPayload, { rejectWithValue }) => {
    try {
      return await post(`/teams`, {
        event_id: eventId,
        team,
        is_draft: isDraft,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateTeamDraft = createAsyncThunk(
  'teamDrafts/save/update',
  async ({ eventId, team, isDraft }: UpdateTeamDraftPayload, { rejectWithValue }) => {
    try {
      return await put(`/teams/${team.id}`, {
        event_id: eventId,
        team,
        is_draft: isDraft,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const validateTeamNameUniqueness = createAsyncThunk(
  'teamDrafts/validate/teamNameUniqueness',
  async (
    { id, eventId, name }: { id: string | undefined; eventId: string; name: string },
    { rejectWithValue },
  ) => {
    try {
      return await get(
        `/events/${eventId}/teams/validate_team_name_uniqueness?team_name=${name}&id=${id}`,
      );
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const teamDraftsSlice = createSlice({
  name: 'teamDraftsSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTeamDraftInEvent.fulfilled, (state, action) => {
        teamDraftAdapter.upsertOne(state, action.payload.team);
        state.loading = false;
      })
      .addMatcher(
        isAnyOf(fetchTeamDraftInEvent.pending, fetchTeamDraftInEvent.rejected),
        (state) => {
          state.loading = true;
        },
      )
      .addMatcher(
        isAnyOf(createTeamDraft.fulfilled, updateTeamDraft.fulfilled),
        (state, action) => {
          teamDraftAdapter.upsertOne(state, action.payload.team);
          state.saving = false;
        },
      )
      .addMatcher(isAnyOf(createTeamDraft.pending, updateTeamDraft.pending), (state) => {
        state.saving = true;
      })
      .addMatcher(isAnyOf(createTeamDraft.rejected, updateTeamDraft.rejected), (state) => {
        state.saving = false;
      });
  },
  selectors: {
    getIsLoading: (state) => state.loading,
    getIsSaving: (state) => state.saving,
    getTeamDraftInEvent: createSelector(
      (state) => state,
      (_, params) => params,
      (state, { id, eventId, userId }: { id?: string; eventId: string; userId: string }) => {
        if (id) return teamDraftAdapter.getSelectors().selectById(state, id);

        const drafts = teamDraftAdapter.getSelectors().selectAll(state);
        return drafts?.find(
          (teamDraft) =>
            teamDraft.event_id === eventId &&
            teamDraft.members.find((member) => member.id === userId),
        );
      },
    ),
  },
});

export const fromTeamDrafts = teamDraftsSlice.selectors;
export default teamDraftsSlice.reducer;
